import gql from "graphql-tag";

const CommandFragment = {
  command: gql`
    fragment CommandFragment on Command {
      id
      num
      createdAt
      state
      client {
        firstName
        lastName
      }
      amount
      state
      deliveryMode
      delivrable
    }
  `,
};
export const GET_COMMANDS_FLORIST = gql`
  query getCommandsFlorist($filter: CommandFilter, $skip: Int, $limit: Int) {
    getCommandsFlorist(filter: $filter, skip: $skip, limit: $limit) {
      nodes {
        ...CommandFragment
      }
      count
    }
  }
  ${CommandFragment.command}
`;

export const GET_COMMAND_BY_NUM = gql`
  query getCommandByNum($num: Int) {
    getCommandByNum(num: $num) {
      id
      florist {
        id
        name
      }
      delivrable
      status {
        state
        date
      }
      items {
        variant {
          id
          photoUrl
          photoUrls {
            photoUrl
          }
          product
          stockManaged
          refVariation
          stockValue
          price
          weight
          width
          height
          depth
          weightCarton
          colorTr
          values {
            label
            value
          }
        }
        product {
          id
          translations {
            country
            name
            description
          }
          photoUrls {
            photoUrl
          }
        }
        quantity
        promotion {
          id
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
        descrption
      }
      num
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      deliveryTime
      deliveryCancellationRaison {
        raison
        canceledDate
        canceledBy
        refunded
      }
      apiResponseLivringInfos {
        trackingUrl
        etaToDestination
        etaToOrigin
        api
        driver {
          firstName
          lastName
          phone
        }
      }
    }
  }
`;

export const GET_COMMAND_BY_ID = gql`
  query getCommandById($id: String) {
    getCommandById(id: $id) {
      itemsCount
      createdFrom {
        id
        num
      }
      commission
      rates
      supplierPaid
      supplierPaidAt
      supplierPaidNote
      supplierPaidMethod
      florist {
        id
        name
        slug
        photoUrl
        address
        city
        storeCountry {
          name
        }
        siret
      }
      cardboards {
        id
        num
        qrcode
        takenFromSupplier
        storedForSending
        onWayToDestination
        storedFordelivering
        delivering
        delivered
        rejected
        raison
        rejectedBy {
          firstName
          lastName
        }
        variant {
          id
          weight
          width
          height
          depth
          photoUrl
          weightCarton
          colorTr
          values {
            label
            value
          }
        }
        product {
          id
          translations {
            country
            name
            description
          }
        }
      }
      qrcode
      packagingList
      state
      captured
      delivrable
      status {
        state
        date
      }

      items {
        _id

        variant {
          variantId
          id
          product
          refVariation
          price
          weight
          width
          height
          depth
          photoUrl
          photoUrls {
            photoUrl
          }
          weightCarton
          colorTr
          values {
            label
            value
          }
        }
        product {
          id
          translations {
            country
            name
            description
          }
          photoUrls {
            photoUrl
          }
          slug
          enableSimpleProduct
          enableMultiVariation
          enableAddons
          refProduct
          product_id
          product_code
        }
        quantity
        promotion {
          id
          title
          value
          promotionType
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
        profile {
          email
        }
        photoUrl
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
        address1
        phone
        descrption
      }
      num
      id
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      paymentMethods
      deliveryTime
      deliveredBy
      paymentMode
      paymentState
      deliveryFee
      feeService
      totalTax
      message
      deliveryCancellationRaison {
        raison
        canceledDate
        canceledBy
        refunded
      }
      shipment {
        id
        name
        description
        origin
        destination
        trackingUrl
        containerNum
      }
      deliveryPoint {
        id
        name
        address
        description
        country {
          id
          name
        }
        type
        price
        blocked
      }
      estimatedDeliveryTime {
        startDate
        endDate
      }
      canceledBy {
        role
        name
        canceledDate
        raison
      }
      promotion {
        id
        code
        value
        promotionType
        enabled
        discountType
      }
      checkout {
        id
        num
      }
    }
  }
`;
export const UPDATE_COMMAND = gql`
  mutation updateCommand(
    $id: ID
    $input: CommandInputState
    $sendItToSupplier: Boolean
    $raison: String
  ) {
    updateCommand(
      id: $id
      input: $input
      sendItToSupplier: $sendItToSupplier
      raison: $raison
    ) {
      id
    }
  }
`;
export const UPDATE_COMMAND_ITEMS = gql`
  mutation updateCommandItems($id: ID, $input: CommandInputState) {
    updateCommandItems(id: $id, input: $input) {
      id
    }
  }
`;

export const GENERATE_PACKAGING_LIST = gql`
  mutation generatePackagingList($id: String) {
    generatePackagingList(id: $id)
  }
`;

export const EXPORT_COMMANDS = gql`
  mutation exportCommands($query: Any, $ids: [ID]) {
    exportCommands(query: $query, ids: $ids)
  }
`;

export const EXPORT_CLIENTS = gql`
  mutation exportClients($query: Any, $ids: [ID]) {
    exportClients(query: $query, ids: $ids)
  }
`;

export const REMBOURCE_ORDER = gql`
  mutation refoundCustomerAmount($order: String) {
    refoundCustomerAmount(order: $order)
  }
`;
export const SET_SUPPLIER_PAYMENT = gql`
  mutation setCommandSupplierPaid(
    $id: ID
    $supplierPaid: Boolean
    $supplierPaidAt: Float
    $supplierPaidNote: String
    $supplierPaidMethod: String
  ) {
    setCommandSupplierPaid(
      id: $id
      supplierPaidAt: $supplierPaidAt
      supplierPaid: $supplierPaid
      supplierPaidNote: $supplierPaidNote
      supplierPaidMethod: $supplierPaidMethod
    )
  }
`;

export const CHANGE_STATE_COMMAND = gql`
  mutation changeStateCommand(
    $id: ID
    $state: String
    $transportType: String
    $deliveryChoice: String
  ) {
    changeStateCommand(
      id: $id
      state: $state
      transportType: $transportType
      deliveryChoice: $deliveryChoice
    ) {
      id
    }
  }
`;
